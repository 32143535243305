import template from './ticketOptions.html';
import styling from './ticketOptions.less';

export default angular.module('eventix.shop.ticketOptions',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.ticketOptions', {
            url: '/ticketOptions?eventId',
            views: {
                shop: {
                    controller: 'TicketOptionsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .directive('repaint', function($timeout) {
        return {
            restrict: 'C',
            link: function(scope, el, attrs) {
                $timeout(() => el.css({ zIndex: 4 }), 500);
            }
        };
    })
    .controller('TicketOptionsController', function($state, $scope, shop, order, products, tickets, LaravelValidationRules, $filter,
        Rollbar, FB, $q, UIMessages, VisitorData, $analytics, OAuthTokens, MetaData, ShopAnalytics) {
        var vm = this;
        if(_.flatten(_.values(order.reservations)).length < 1)
            $state.go('eventix.shop.tickets', { shopId: shop.guid , eventId: $state.params.eventId});
        if(order.receiver.email || FB.disabled) // Don't show facebook login button if profile is already filled
            vm.manualEntry = true;
        vm.order = order;
        vm.shop = shop;
        vm.tickets = tickets;
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');

        vm.hasOpened = { };
        _.forEach(vm.order.reservations, (ticketReservations, ticketGuid) => {
            if (!vm.order.showTicketReservationForm(ticketGuid))
                return;
            _.forEach(ticketReservations, reservation => {
                vm.hasOpened[reservation.reservation] = false;
            });
        });

        // merge Reservations
        vm.applicableReservations = [];

        let first = true;
        _.forEach(vm.order.reservations, function(reservations, ticketGuid){
            if(reservations.length > 0) {
                reservations[0].isOpen = first;
                if(first)
                    vm.hasOpened[reservations[0].reservation] = true;

                first = false;
                vm.applicableReservations = vm.applicableReservations.concat(reservations);
            }
        });

        vm.products = products;
        vm.verifyEmail = function(error, valid, suggestion) {
            if(!suggestion)
                return;
            if(!_.get(vm.order.receiver,'$errors.email'))
                _.set(vm.order.receiver, '$errors.email', []);
            vm.order.receiver.$errors.email.push($filter('translate')('common.notice.didYouMean', {suggestion: suggestion }));
        };

        vm.back = () => {
            return $state.go('eventix.shop.tickets', { shopId: shop.guid , eventId: $state.params.eventId});
        };
        vm.next = () => {
            let collapseToOpen;
            let invalid = order.hasInvalidReservations();
            if(invalid) {
                const ticketReservations = _.flatten(_.values(vm.order.reservations));
                const invalidReservation = _.find(ticketReservations, r => r.$invalid());
                collapseToOpen = _.find($scope.accordion.groups, group => {
                    const collapseReservation = _.get(group, '$parent.ticketReservation.reservation');
                    return collapseReservation === invalidReservation.reservation;
                });
                if(!collapseToOpen.isOpen)
                    collapseToOpen.toggleOpen();
            } else{
                let unopened = _.findKey(vm.hasOpened, v => !v);
                if(unopened) {
                    let toOpen = _.find($scope.accordion.groups, g => _.get(g.$parent,'ticketReservation.reservation') === unopened);

                    if(toOpen) {
                        vm.hasOpened[unopened] = true;
                        toOpen.toggleOpen();
                        return;
                    }
                }
            }

            $state.go('eventix.shop.personalDetails',{ shopId: shop.guid , eventId: $state.params.eventId});
        };

        ShopAnalytics.checkoutStepOne();
        /*
        * We keep tabs on whether people understand the accordion.
        * If they have unopened tabs, goto that tab when you press "Next"
        */
        vm.logOpeningCollapse = function(reservationGuid) {
            vm.hasOpened[reservationGuid] = true;
        };

        vm.facebookLogin = function() {
            order.loginWithFacebook().catch(() => {
                UIMessages.push('common.shop.facebookError');
                return $q.resolve();
            }).then(() => {
                vm.manualEntry = true;
            });
        };
    }).name;
