import template from './shop.html';
import Errors from './error/error.js';
import Tickets from './tickets/tickets.js';
import Header from './header/header.js';
import HeaderTemplate from './header/header.html';
import personalDetails from './personalDetails/personalDetails.js';
import ticketOptions from './ticketOptions/ticketOptions.js';
import angulartics from 'angulartics';
import style from './shop.less';
import Events from './events/events.js';
import Summary from './summary/summary.js';

export default angular.module('eventix.shop',[ Tickets, Header, angulartics, Errors, personalDetails, Summary, Events, ticketOptions])
    .config(function($stateProvider, OAuthHeadersProvider) {
        $stateProvider.state('eventix.shop',{
            url: '/:shopId?highlight',
            params: {
                highlight: { inherit: false, dynamic: true }
            },
            resolve: /*@ngInject*/{
                tokens: function(Auth) {
                    return Auth.refresh('client_credentials');
                },
                shop: function($http, $transition$, AskAPI, Shop, tokens, Rollbar, frameInfo, $state, $log,
                    Currencies, $q) {
                    if(frameInfo.isFramed && frameInfo.width < 330) {
                        $transition$.abort();
                        $state.go('eventix.breakFrame', { shopId: $transition$.params().shopId });
                        return $q.reject('Going to breakFrame');
                    }
                    Rollbar.configure({
                        payload: {
                            token: tokens.access_token
                        }
                    });
                    let shopId = $transition$.params().shopId;
                    let url = '/' + shopId + '/data';
                    if(USE_CACHED == 1){
                        url = 'https://ev3b.s3-eu-west-1.amazonaws.com/shops/' + shopId + '/' + shopId + '.json';
                    }
                    return $http.get(url)
                        .then(response => {
                            return AskAPI.restore(response);
                        }).then(() => {
                            let shop = Shop.cached.first();
                            Currencies.use(shop.currency);
                            return shop;
                        });
                },
                order: function(shop, OrderManager, frameInfo, $q) {
                    return OrderManager.init();
                },
                events: function(order, Event) {
                    return Event.query().then(events => {
                        return _.sortBy(events, function(o) { return o.start; });
                    });
                },
                metaData: function(MetaData, order) {
                    return MetaData.query();
                },
                products: function(Product, order) {
                    return Product.query();
                },
                tickets: function(Ticket, order, $q, $filter) {
                    return Ticket.query().then(tickets => {
                        return $filter('sortByPredecessor')(tickets);
                    });
                },
                paymentMethods: function(PaymentMethod, shop, $filter) {
                    return shop.$queryPaymentMethod();
                },
                collapses: function(Collapse, order, $q, $filter) {
                    return Collapse.query().then(collapses => {
                        return $filter('sortByPredecessor')(collapses);
                    });
                }
            },
            views: {
                header: {
                    controller: 'HeaderController',
                    controllerAs: 'vm',
                    templateUrl: HeaderTemplate
                },
                main: {
                    controller: 'ShopFrontendController',
                    controllerAs: 'vm',
                    templateUrl: template
                }
            }
        });
    })
    .run(function($rootScope, stateChangeErrorService, $state, UIMessages, $transitions, Highlighter, $q) {
        $rootScope.$on('APIMessage', function(event, status, message) {
            if(status >= 400) {
                return UIMessages.push({
                    type: 'danger',
                    message: message
                });
            }
            return UIMessages.push(message);
        });

        $transitions.onSuccess({}, function($trans) {
            let params = $trans.params();
            if(params.highlight)
                Highlighter.mark(params.highlight);
        });
        $transitions.onError({}, function(trans) {
            let fromState = trans.from();
            let toState = trans.to();
            let params = trans.params();
            if(fromState.name === 'eventix.shoperror' || toState.name === 'eventix.shoperror')
                return $q.resolve();
            let error = trans.error();
            // SUPERSEDED = 2, ABORTED = 3, INVALID = 4, IGNORED = 5, ERROR = 6
            if(error.type === 5 || error.type === 2)
                return $q.resolve();
            stateChangeErrorService(toState, trans.params(), _.get(error, 'detail'));
            return $state.go('eventix.shoperror', { shop: params.shopId });
        });
    })
    .controller('ShopFrontendController', function(UIMessages, $state, shop, order, SeatsIO,
        GoogleTagManager, Currencies, VisitorData, beforeUnload, $rootScope, paymentMethods, StripeSDK, frameInfo){
        var vm = this;
        vm.messages = UIMessages;
        vm.order = order;
        vm.shop = shop;
        if(shop.seats_public_key && shop.seats_public_key.length)
            SeatsIO.load();
        Currencies.use(vm.shop.currency);
        vm.frameInfo = frameInfo;

        beforeUnload(function() {
            order.releaseAll();
            $rootScope.$digest();
        });

        if(shop.google_tag)
            GoogleTagManager.addContainer(shop.google_tag);
        if($state.is('eventix.shop'))
            $state.go('eventix.shop.tickets', { shopId: shop.guid }, { location: 'replace' });

        let stripeMethod = _.find(paymentMethods, p => p.isStripe());
        if(stripeMethod)
            StripeSDK.get(stripeMethod.stripe_public_key);
    }).name;
