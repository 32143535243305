import template from './tickets.html';
import style from './tickets.less';
import seatsTemplate from './seats/seats.html';

export default angular.module('eventix.shop.tickets', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.tickets', {
            url: '/tickets?eventId',
            views: {
                'shop': {
                    controller: 'TicketsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('TicketsController', function(UIMessages, shop, order, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
        $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };

        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.tickets = order.tickets;
        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;
        vm.noTickets = true;

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        vm.initDating = true;
        let today = moment();
        let minStart = moment("2019-11-28");
        let minDate;
        if(today.isAfter(minStart)){
            minDate = today;
        }
        else{
            minDate = minStart;
        }

        let currentDate;
        if(vm.eventFilter){
            let currentEvent = vm.events[vm.eventFilter];
            let split = currentEvent.name.split('-');
            let year = 2019;
            if(currentEvent.name.includes(" Jan")){ // -_- didnt want to parse all event dates / lazy
                year = 2020;
            }
            currentDate =  moment(split[1].trim()  + " " + year);
        }
        else{
            currentDate = minDate;
        }

        if(!vm.eventFilter){
            updateStateToEvent();
        }

        vm.pickedDate = currentDate;
        vm.dateOptions = {
            initDate: currentDate.toDate(),
            minDate: minDate.toDate(),
            maxDate: moment('2020-1-19').toDate(),
            showWeeks: true,
            startingDay: 1,
            maxMode: 'day'
        };


        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        // if(vm.fourStepWizard && !vm.eventFilter)
        //     $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        vm.setTickets = function() {
            vm.prefix = '';
            if(vm.eventFilter){
                let currentEvent = vm.events[vm.eventFilter];
                let split = currentEvent.name.split('-');
                vm.prefix = split[0].trim();
            }

            vm.currentTickets = {};
            vm.currentTicketGuids = [];

            vm.currentEvents = _.filter(_.map(vm.events, function(e) {
                if(vm.eventFilter && vm.eventFilter != e.guid){
                    return null;
                }
                let splits = e.name.split('-')[1];
                let daySplits = splits.split(' ');
                e.dayName = daySplits[1];
                e.dayNumber = daySplits[2];
                e.dayMonth = daySplits[3];
                if (e.name.startsWith(vm.prefix)) return e;
                return null;
            }));

            vm.currentEventGuids = vm.currentEvents.map(e => e.guid);
            _.each(vm.tickets, function(tickets,eventId) {
                if(_.indexOf(vm.currentEventGuids, eventId) !== -1){
                    let sortedTickets = _.sortBy(tickets, 'name');
                    sortedTickets = _.forEach(sortedTickets, function (ticket) {
                        let names = ticket.name.split('-');
                        ticket.displayName = names[1] + " - " + names[2];
                    });
                    if(sortedTickets.length > 0){
                        vm.noTickets = false;
                    }

                    if (_.indexOf(vm.currentEventGuids, eventId) !== -1) {
                        if (!vm.eventFilter) {
                            vm.currentTickets[eventId] = sortedTickets;
                            vm.currentTicketGuids = vm.currentTicketGuids.concat(tickets.map(t => t.guid));
                        } else if (vm.eventFilter === eventId) {
                            vm.currentTickets[eventId] = sortedTickets;
                            vm.currentTicketGuids = vm.currentTicketGuids.concat(tickets.map(t => t.guid));
                        }
                    }
                }
            });
            let firstCollape = true;
            _.each(vm.collapses, function(c){
                c.custom_is_open = false;
                if(firstCollape) {
                    var presents = _.intersection(c.ticket_guids, vm.currentTicketGuids);
                    if (presents.length > 0) {
                        c.custom_is_open = true;
                        firstCollape = false;
                    }
                }
            });
        };

        vm.updateEvent = updateStateToEvent;

        function updateStateToEvent() {
            if(vm.initDating){
                vm.initDating = false;
                return;
            }
            let CurrentSearch = moment(vm.pickedDate).locale('en').format('ddd D MMM');
            _.each(vm.events, function(e){
                let eventName = e.name;
                if(eventName.indexOf(CurrentSearch) !== -1){
                    vm.noTickets = false;
                    $state.go('.', {eventId: e.guid });
                    return;
                }
                vm.noTickets = true;
            });
        }
        $scope.$watch('vm.eventFilter', vm.setTickets, true);
        $scope.$watch('vm.pickedDate', vm.updateEvent, true);

        vm.setCurrentEvent = function(eventId){
            $state.go('.', {eventId: eventId});
        }

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.openSeatSelection = function(ticket, eventId) {
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            if (_.flatten(_.values(order.reservations)).length < 1)
                UIMessages.push('common.shop.order.empty');
            else {
                let showOptionals = false;
                _.forEach(vm.order.reservations, function(reservations, ticketGuid){
                    if(vm.order.showTicketReservationForm(ticketGuid)) // has optionals
                        showOptionals = true;
                });
                if(showOptionals)
                    $state.go('eventix.shop.ticketOptions', {shopId: shop.guid, eventId: $state.params.eventId});
                else
                    $state.go('eventix.shop.personalDetails', {shopId: shop.guid, eventId: $state.params.eventId});
            }
        };

        vm.reserve = function(ticketGuid, eventId){
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.toId = function(string){
            return string.replace(/\W/g, '');
        };


    }).directive('reservationSummaryCustom', function(Locales, Currencies) {
        return {
            restrict: 'E',
            replace: true,
            template: `<div class="ticket-cost col-xs-3">
    <span class="sum" ng-show="(priceQuantities | lodash:'keys').length <= 2">
        <span class="other-price" ng-repeat="(price, quantity) in priceQuantities" ng-hide="price == currentPrice">
            {{ quantity }} &times;
            <exchange-currency convert-to="currencies.selected" locale="locales.selected"
                amount="price" convert-from="shop.currency" ng-class="{'text-danger': price != ticket.minimumPrice && coupon, 'strike-out': quantity == 0 }"></exchange-currency>
                <br/>
        </span>
        {{ priceQuantities[currentPrice] }} &times;
        <exchange-currency  convert-to="currencies.selected" locale="locales.selected"
            amount="currentPrice" convert-from="shop.currency" ng-class="{'text-danger': currentPrice != ticket.minimumPrice }"></exchange-currency>
    </span>
    <span class="sum" ng-show="(priceQuantities | lodash:'keys').length > 2">
        {{ priceQuantities[currentPrice] }} &times;
        <exchange-currency convert-to="currencies.selected" locale="locales.selected"
            amount="currentPrice" convert-from="shop.currency" ng-class="{'text-danger': currentPrice != ticket.minimumPrice }"></exchange-currency>
        <span class="other-price">
            {{ reservations.length - priceQuantities[currentPrice] }} &times; &hellip;
        </span>
    </span>
<!--    <span class="total">-->
<!--        <exchange-currency convert-to="currencies.selected" locale="locales.selected"-->
<!--            amount="total" convert-from="shop.currency"></exchange-currency>-->
<!--    </span>-->
</div>`,
            scope: {
                ticket: '=',
                reservations: '=',
                shop: '=',
                coupon: '='
            },
            link: function(scope, el, attr) {
                scope.currentPrice = scope.ticket.discountPrice || scope.ticket.minimumPrice;
                scope.priceQuantities = {};
                scope.priceQuantities[scope.currentPrice] = 0;
                scope.total = 0;
                scope.currentPrice = 0;

                scope.currencies = Currencies;
                scope.locales = Locales;

                scope.$watch('ticket', ticket => {
                    if(!ticket) return;
                    scope.currentPrice = (typeof ticket.discountPrice === 'undefined' ? ticket.minimumPrice : ticket.discountPrice);
                    if(!_.has(scope.priceQuantities, scope.currentPrice))
                        scope.priceQuantities[scope.currentPrice] = 0;
                }, true);

                scope.$watch('reservations', reservations => {
                    if(scope.ticket)
                        scope.currentPrice = scope.ticket.discountPrice || scope.ticket.minimumPrice;
                    scope.priceQuantities = {};
                    scope.priceQuantities[scope.currentPrice] = 0;
                    scope.total = 0;

                    if(!reservations || reservations.length === 0)
                        return;

                    let substractAmountForDisplay = 0;
                    if(scope.currentPrice !== 0 && !scope.ticket.include_service_cost_in_price){
                        let varying = scope.currentPrice * scope.ticket.service_cost_percentage;
                        substractAmountForDisplay = scope.ticket.service_cost + varying;
                    }

                    let sorted = _.groupBy(reservations, r => Math.round(r.price - substractAmountForDisplay));
                    let prices = _.keys(sorted);
                    _.forEach(sorted, (reservations, price) => {
                        if(!_.has(scope.priceQuantities, 'price'))
                            scope.priceQuantities[price] = 0;
                        scope.priceQuantities[price] += reservations.length;
                    });
                    scope.total = _.sumBy(reservations, r => Math.round(r.price - substractAmountForDisplay));
                }, true);
            }
        };
    }).name;
